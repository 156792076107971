var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-submit",
      "cancel-variant": "secondary",
      "ok-title": "Kategori Harga",
      "cancel-title": "Harga",
      "centered": "",
      "title": "Form Kategori Harga"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": _vm.submit
          }
        }, [_vm._v(" Submit ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": _vm.resetForm
          }
        }, [_vm._v(" Batal ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.active,
      callback: function ($$v) {
        _vm.active = $$v;
      },
      expression: "active"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "nama"
    }
  }, [_vm._v("Nama Kategori")]), _c('b-form-input', {
    attrs: {
      "id": "nama",
      "type": "text",
      "placeholder": "Ex: Grosir"
    },
    model: {
      value: _vm.form.nama,
      callback: function ($$v) {
        _vm.$set(_vm.form, "nama", $$v);
      },
      expression: "form.nama"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }